import styled from "styled-components"

import theme from "../common/theme"

const PageWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Header = styled.header`
  max-width: 720px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const PageTitle = styled.h1`
  margin: 0;
  font-size: 2.5rem;
  color: ${theme.colors.white};
  text-transform: uppercase;
`

const Card = styled.div`
  margin-bottom: 0.25rem;
  padding: 1rem;
  background: ${theme.colors.black};
  box-shadow: ${theme.shadow};
`

const Link = styled.a`
  color: ${theme.colors.white};
  text-decoration: none;
  transition: color 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);

  > svg {
    transition: color 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
  }

  &:hover,
  &:hover > svg {
    color: ${theme.colors.grey};
  }
`

export { PageWrapper, Header, PageTitle, Card, Link }
