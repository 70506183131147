const theme = {
  colors: {
    white: "#ffffff",
    grey: "#333333",
    black: "#000000",
    base0: "#00b7e6",
    base1: "#23c0e8",
    base2: "#46cae8",
    base3: "#61cde6",
    base4: "#74d6e8",
  },
  shadow: "5px 5px 20px 0 rgba(0, 0, 0, 0.5)",
}

export default theme
