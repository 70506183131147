import React from "react"
import PropTypes from "prop-types"
import styled, { ThemeProvider } from "styled-components"
import { config, library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope, faIgloo } from "@fortawesome/free-solid-svg-icons"
import "@fortawesome/fontawesome-svg-core/styles.css"

import theme from "../common/theme"

import "./layout.css"
import "../common/theme.css"

config.autoAddCss = false
library.add(fab, faEnvelope, faIgloo)

const Container = styled.div`
  width: 100%;
  margin: 0;
  padding: 0 1rem;
`

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 0;
`

const Layout = ({ children }) => (
  <Container>
    <ContentWrapper>
      <ThemeProvider theme={theme}>
        <main>{children}</main>
      </ThemeProvider>
    </ContentWrapper>
  </Container>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
